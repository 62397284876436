<!-- TODO: Cambiar el donde se guarda el nivel de la competencia -->

<template>
  <b-container fluid>
    <b-modal id="modal-competencia" class="modal-sq" size="lg" hide-footer>
      <template #modal-header="{ close }">
        <div class="d-flex justify-content-between" style="width: 100%">
          <p class="mb-0 h4">
            <strong>{{ empresa.nombre == "Grupo GSE" ? "habilidad" : "competencia" }} información</strong>
          </p>
          <b-button @click="close()" class="btn btn-pm" variant="danger">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <template #default>
        <p><strong>Tipo: </strong>{{ modalCompetencia.tipoCompetencia.descripcion }}</p>
        <p><strong>Titulo: </strong>{{ modalCompetencia.titulo }}</p>
        <p><strong>Descripción: </strong>{{ modalCompetencia.descripcion }}</p>
      </template>
    </b-modal>
    <b-modal id="modal-administrador-funciones" class="modal-sq" size="lg" hide-footer scrollable>
      <template #modal-header="{ close }">
        <div class="d-flex justify-content-between" style="width: 100%">
          <p class="mb-0 h4"><strong>Administrador de funciones</strong></p>
          <b-button @click="close()" class="btn btn-pm" variant="danger">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <template #default>
        <b-row class="my-2">
          <b-col>
            <label>Agregar función</label>
            <b-input-group>
              <b-form-input @keydown.enter="agregarFuncion()" v-model="nuevaFuncion" :disabled="isLoadingFunction"></b-form-input>
              <b-input-group-append>
                <b-button style="min-width: 80px" variant="info" @click.prevent="agregarFuncion()" :disabled="isLoadingFunction">
                  <i v-if="isLoadingFunction" class="fas fa-spinner fa-spin"></i>
                  <span v-else>Agregar</span>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col>
            <b-list-group>
              <p>Lista de funciones</p>
              <div v-if="funcionesDelCargo.length == 0">
                <b-list-group-item>
                  <p class="mb-0">No existen funciones</p>
                </b-list-group-item>
              </div>
              <div v-else>
                <b-list-group-item v-for="(item, idx) in funcionesDelCargo" :key="idx">
                  <div class="d-flex justify-content-between" v-if="idx != funcionEnEdicion">
                    <p class="mb-0">{{ item.nombre }}</p>
                    <div>
                      <b-button @click.prevent="editarFuncion(idx)" class="btn btn-pm" variant="transparent">
                        <i class="fas fa-solid fa-pen px-1 text-success"></i>
                      </b-button>
                      <b-button @click.prevent="deleteFuncion(idx)" class="btn btn-pm" variant="transparent">
                        <i class="fas fa-solid fa-trash px-1 text-danger"></i>
                      </b-button>
                    </div>
                  </div>
                  <div v-else>
                    <b-input-group>
                      <b-form-input @keydown.enter="saveFuncion()" v-model="editarFuncionMessage" :disabled="isLoadingFunction"></b-form-input>
                      <b-input-group-append>
                        <b-button style="min-width: 80px" variant="info" @click.prevent="saveFuncion()" :disabled="isLoadingFunction">
                          <i v-if="isLoadingFunction" class="fas fa-spinner fa-spin"></i>
                          <span v-else>Guardar</span>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                </b-list-group-item>
              </div>
            </b-list-group>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <section>
      <b-row>
        <b-col sm="12">
          <card class="pb-2">
            <template v-slot:headerTitle>
              <h4 class="card-title"><b>Nuevo cargo</b></h4>
            </template>
            <h4 class="px-4 py-4"><b>Datos de identificación</b></h4>
            <form @submit.prevent>
              <div class="row px-5">
                <b-row>
                  <div class="col-md-6">
                    <div>
                      <label class="form-label">Nombre Cargo</label>
                      <input required v-model="cargoBase.nombre" placeholder="" type="text" class="form-control" id=" " />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div>
                      <label class="form-label">Nivel Cargo</label>
                      <b-form-select required v-model="cargoBase.tipoCargoId" text-field="descripcion" value-field="id" :options="ListTipoCargo">
                        <template #first>
                          <b-form-select-option selected :value="null" disabled> Seleccionar </b-form-select-option>
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Sucursal</label>
                      <b-form-select
                        required
                        v-model="cargoBase.sucursalId"
                        text-field="descripcion"
                        value-field="id"
                        :options="ListSucursales"
                        @change.native="getDepartamentosBySucursalId(cargoBase.sucursalId)"
                      >
                        <template #first>
                          <b-form-select-option selected :value="null" disabled> Seleccionar </b-form-select-option>
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Departamento</label>
                      <b-form-select
                        required
                        v-model="cargoBase.departamentoId"
                        text-field="descripcion"
                        value-field="id"
                        :options="ListDepartamento"
                        @change.native="getAreasByDepartamentoId(cargoBase.departamentoId)"
                      >
                        <template #first>
                          <b-form-select-option selected :value="null" disabled v-if="cargoBase.sucursalId"> Seleccionar </b-form-select-option>
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Area</label>
                      <b-form-select required v-model="cargoBase.areaId" text-field="descripcion" value-field="id" :options="listArea">
                        <template #first>
                          <b-form-select-option selected :value="null" disabled v-if="cargoBase.departamentoId"> Seleccionar </b-form-select-option>
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Cargo superior</label>
                      <b-form-select required v-model="cargoBase.cargoDependienciaId" text-field="nombre" value-field="id" :options="ListCargo">
                        <template #first>
                          <b-form-select-option selected :value="null" disabled> Seleccionar </b-form-select-option>
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                </b-row>
              </div>
            </form>
            <div>
              <h4 class="px-4 pt-4 pb-2">
                <b>{{ empresa.nombre == "Grupo GSE" ? "Habilidades" : "Competencias" }}</b>
              </h4>
              <b-row class="px-4 my-3">
                <b-col>
                  <b-list-group class="limit-scroll-height">
                    <b-list-group-item>
                      <p class="mb-1 text-center">
                        <strong> Banco de {{ empresa.nombre == "Grupo GSE" ? "habilidades" : "competencias" }} </strong>
                      </p>
                      <b-row>
                        <b-col>
                          <p class="my-1">Filtrado de {{ empresa.nombre == "Grupo GSE" ? "habilidades" : "competencias" }}</p>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form-input placeholder="Buscar coincidentes" @keydown.enter.prevent v-model="inputFilterCoincidente" class="sm-input"></b-form-input>
                        </b-col>
                        <b-col>
                          <b-form-select v-model="inputFilterTipo" class="sm-input text-light" :options="tiposDeCompetencia"></b-form-select>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                    <b-list-group-item v-for="competencia in bancoDeCompetenciasFiltrado" :key="competencia.id">
                      <p class="my-1">
                        <strong>{{ competencia.tipoCompetencia.descripcion }}</strong>
                      </p>
                      <p class="mt-1 mb-3 text-justify">{{ competencia.titulo }}</p>
                      <div class="d-flex justify-content-between">
                        <b-button class="btn" variant="info" @click="showModalCompetencia(competencia)">Mas información</b-button>

                        <div>
                          <b-button v-if="isCompetenciaSelected(competencia)" @click="eliminarDeLosSeleccionados(competencia)" class="btn btn-pm" variant="danger">
                            <i class="fas fa-minus"></i>
                          </b-button>
                          <b-button v-else @click="agregarCompetencia(competencia)" class="btn btn-pm" variant="primary">
                            <i class="fas fa-plus"></i>
                          </b-button>
                        </div>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
                <b-col>
                  <b-list-group class="limit-scroll-height">
                    <b-list-group-item>
                      <p class="mb-1 text-center">
                        <strong> Lista de {{ empresa.nombre == "Grupo GSE" ? "habilidades" : "competencias" }} seleccionadas </strong>
                      </p>
                    </b-list-group-item>
                    <b-list-group-item v-for="competencia in selectedCompetencias" :key="competencia.id">
                      <p class="my-1">
                        <strong>{{ competencia.tipoCompetencia.descripcion }}</strong>
                      </p>
                      <p class="mt-1 mb-3 text-justify">{{ competencia.titulo }}</p>

                      <b-row>
                        <b-col>
                          <b-form-group>
                            <label class="mb-1">Nivel de {{ empresa.nombre == "Grupo GSE" ? "habilidad" : "competencia" }}</label>
                            <b-form-select class="sm-input" required :options="nivelesDeCompetencia" v-model="competencia.nivel"></b-form-select>
                          </b-form-group>
                        </b-col>
                        <b-col v-if="competencia.tipoCompetencia.descripcion == 'Responsabilidad'">
                          <b-form-group>
                            <label class="mb-1">Seleccionar función</label>
                            <b-form-select
                              class="sm-input"
                              required
                              :options="funcionesDelCargo"
                              v-model="competencia.funcion"
                              value-field="id"
                              text-field="nombre"
                            ></b-form-select>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <div class="d-flex justify-content-between">
                        <div>
                          <b-button class="btn" variant="info" @click="showModalCompetencia(competencia)"> Mas información </b-button>
                          <b-button v-if="competencia.tipoCompetencia.descripcion == 'Responsabilidad'" class="btn" variant="info" @click="showModalAdministradorDeFunciones()">
                            Administrador de funciones
                          </b-button>
                        </div>
                        <div>
                          <b-button @click="eliminarDeLosSeleccionados(competencia)" class="btn btn-pm" variant="danger">
                            <i class="fas fa-minus"></i>
                          </b-button>
                        </div>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
              </b-row>
            </div>
            <div class="d-flex flex-row-reverse px-4" style="width: 100%" v-if="id">
              <!-- Solo debe mostrarse si ya existe el cargo -->
              <button class="mx-1 btn px-4 py-2 btn-primary" @click="updateData" :disabled="getLoading">
                <i v-if="getLoading" class="fas fa-spinner fa-spin"></i>
                <span v-else> Guardar </span>
              </button>
            </div>
          </card>
        </b-col>
      </b-row>
    </section>
  </b-container>
</template>

<script>
const Swal = require("sweetalert2");
import { mapGetters } from "vuex";

export default {
  name: "DataTable",
  data() {
    return {
      ListSucursales: [],
      ListDepartamento: [],
      listArea: [],

      ListCargo: [],
      ListTipoCargo: [],

      listaCompetencias: [],
      selectedCompetencias: [],

      nivelesDeCompetencia: [],
      funcionesDelCargo: [],
      nuevaFuncion: "",
      funcionEnEdicion: null,
      editarFuncionMessage: "",

      inputFilterCoincidente: "",
      inputFilterTipo: null,

      modalCompetencia: {},

      // Pruebas
      id: null,
      isLoadingFunction: false,

      cargoBase: {
        sucursalId: null,
        departamentoId: null,
        areaId: null,
        nombre: null,
        tipoCargoId: null,
        cargoDependienciaId: null,
      },
      empresa: {},
    };
  },
  mounted() {
    // Traer en el siguienteorden.  Sucursal -> Departamento -> Area -> Cargo
    this.id = this.$route.params.id;

    let { empresa } = this.$store.getters.userLoggedIn;
    this.empresa = empresa;
    this.getCargoCompetenciaNivelesYFunciones();
    this.getSucursalesByEmpresaId();
    this.getNivelesDeCargo();
    this.getListaCargo(empresa.id);

    this.initData();
  },
  computed: {
    ...mapGetters(["getLoading"]),
    bancoDeCompetenciasFiltrado() {
      return this.listaCompetencias.filter((item) => {
        const tipoMatch = !this.inputFilterTipo || item.tipoCompetencia.descripcion === this.inputFilterTipo;
        const coincidenteMatch = !this.inputFilterCoincidente || (item.titulo && item.titulo.toLowerCase().includes(this.inputFilterCoincidente.toLowerCase()));
        return tipoMatch && coincidenteMatch && item.tipoCompetencia.descripcion != "Responsabilidad";
      });
    },
    tiposDeCompetencia() {
      //Crea un conjunto de tipos de competencia
      let tipos = new Set();
      this.listaCompetencias.forEach((item) => tipos.add(item.tipoCompetencia.descripcion));
      // Eliminar el tipo de competencia 'Responsabilidad'
      tipos.delete("Responsabilidad");
      return [{ value: null, text: "Por tipo" }, ...tipos];
    },
  },
  methods: {
    async initData() {
      await this.getCompetencias();
      let response = await this.$store.getters.fetchGet({ path: "Cargo/all/" + this.$route.params.id });
      let result = await response.json();
      await this.getDepartamentosBySucursalId(result.sucursalId);
      await this.getAreasByDepartamentoId(result.departamentoId);
      console.log("Nuevo controller: ", result);
      this.cargoBase = { ...result };
      delete this.cargoBase.competencias;

      this.selectedCompetencias = result.competencias.map((element) => {
        let competencia = this.listaCompetencias.find((item) => item.id == element.competenciaId);
        competencia.nivel = element.cargoCompetenciaNivelId;
        competencia.funcion = element.cargoFuncionesId;
        return Object.assign(competencia, element);
      });

      this.selectedCompetencias = this.selectedCompetencias.filter((item) => item.tipoCompetencia.descripcion != "Responsabilidad");

      // console.log("selectedCompetencias", this.selectedCompetencias);
    },
    getCargoCompetenciaNivelesYFunciones() {
      this.$store.getters
        .fetchGet({ path: "CargoCompetencia/GetAllCargoCompetenciaNivel" })
        .then((response) => {
          if (!response.ok) {
            return response.text();
          }
          return response.json();
        })
        .then((result) => {
          if (typeof result == "object") {
            this.nivelesDeCompetencia = result.map((item) => {
              return {
                value: item.id,
                text: item.value,
              };
            });
          }
        });

      this.$store.getters
        .fetchGet({ path: "CargoCompetencia/GetFuncionesByCargoId/" + this.$route.params.id })
        .then((response) => {
          if (!response.ok) {
            return response.text();
          }
          return response.json();
        })
        .then((result) => {
          if (typeof result == "object") {
            this.funcionesDelCargo = result;
          }
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    },
    isCompetenciaSelected(competencia) {
      return this.selectedCompetencias.find((item) => item.id == competencia.id);
    },
    agregarCompetencia(element) {
      if (this.id == null) {
        Swal.fire({
          title: "Error!",
          text: "Debe crear el cargo primero.",
          icon: "error",
          confirmButtonText: "Continuar",
        });
      } else {
        this.selectedCompetencias.push(element);
      }
    },
    eliminarDeLosSeleccionados(element) {
      this.selectedCompetencias = this.selectedCompetencias.filter((item) => item.id != element.id);
    },
    getListaCargo(empresaId) {
      this.$store.getters
        .fetchGet({ path: `Cargo/ListCargo/${empresaId}` })
        .then((res) => res.json())
        .then((res) => (this.ListCargo = res))
        .catch((err) => console.log("Revisar conexión: ", err));
    },
    getNivelesDeCargo() {
      this.$store.getters
        .fetchGet({ path: `TipoCargo/TiposDeCargo` })
        .then((res) => res.json())
        .then((res) => (this.ListTipoCargo = res))
        .catch((err) => console.log("Revisar conexión: ", err));
    },
    async getAreasByDepartamentoId(id) {
      // console.log(id);

      let response = await this.$store.getters.fetchGet({ path: `Area/ListAreaDepartamento/${id}` });
      let result = await response.json();
      this.listArea = result;
    },
    async getDepartamentosBySucursalId(sucursalId) {
      // console.log(sucursalId);
      let response = await this.$store.getters.fetchGet({ path: `Departamento/ListDepartamentoSucursal/${sucursalId}` });
      let result = await response.json();
      this.ListDepartamento = result;
    },
    getSucursalesByEmpresaId() {
      let { empresa } = this.$store.getters.userLoggedIn;

      this.$store.getters
        .fetchGet({ path: `Sucursal/ListSucursalEmpresa/${empresa.id}` })
        .then((res) => res.json())
        .then((res) => (this.ListSucursales = res))
        .catch((err) => console.log("Revisar conexión: ", err));
    },
    showModalCompetencia(data) {
      this.modalCompetencia = data;
      this.$bvModal.show("modal-competencia");
    },
    showModalAdministradorDeFunciones() {
      this.$bvModal.show("modal-administrador-funciones");
    },
    agregarFuncion() {
      if (this.nuevaFuncion == "") return;
      let data = {
        CargoId: this.id,
        Nombre: this.nuevaFuncion,
      };
      this.isLoadingFunction = true;

      this.$store.getters
        .fetchPost({ path: "CargoCompetencia/CrearFuncion", data })
        .then((response) => response.json())
        .then((result) => {
          this.funcionesDelCargo.push(result);
          this.nuevaFuncion = "";
        })
        .catch((error) => {
          console.log("Error:", error);
        })
        .finally(() => {
          this.isLoadingFunction = false;
        });
    },
    editarFuncion(idx) {
      this.funcionEnEdicion = idx;
      this.editarFuncionMessage = this.funcionesDelCargo[idx].nombre;
    },
    async saveFuncion() {
      const aux = { ...this.funcionesDelCargo[this.funcionEnEdicion] };
      const data = this.funcionesDelCargo[this.funcionEnEdicion];
      data.nombre = this.editarFuncionMessage;

      this.isLoadingFunction = true;
      const response = await this.$store.getters.fetchPut({ path: "CargoCompetencia/UpdateFuncion/" + data.id, data });
      const result = await response.json();

      if (response.ok) {
        this.funcionesDelCargo[this.funcionEnEdicion] = result;
      } else {
        this.funcionesDelCargo[this.funcionEnEdicion] = aux;
      }

      this.isLoadingFunction = false;
      this.editarFuncionMessage = "";
      this.funcionEnEdicion = null;
    },
    deleteFuncion(idx) {
      this.$store.getters
        .fetchDelete({ path: "CargoCompetencia/EliminarFuncion/" + this.funcionesDelCargo[idx].id })
        .then((response) => response.json())
        .then((result) => {
          console.log("Resultado:", result);
          this.funcionesDelCargo.splice(idx, 1);
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    },
    async getCompetencias() {
      let response = await this.$store.getters.fetchGet({ path: `Competencia/Competencias` });
      let result = await response.json();
      this.listaCompetencias = result;
      console.log("competencias ", result);
    },
    async updateData() {
      let competencias;

      try {
        competencias = this.selectedCompetencias.map((element) => {
          console.log(element);
          if (element.tipoCompetencia.descripcion == "Responsabilidad" && !("funcion" in element)) throw new Error("Agregar función a \n" + element.titulo);
          if (!("nivel" in element)) throw new Error("Agregar nivel a \n" + element.titulo);

          // if( element.funcion != null ) throw new Error("Agregar función a \n" + element.titulo);
          // if( element.nivel != null ) throw new Error("Agregar nivel de competencia a \n" + element.titulo);

          return {
            CompetenciaId: element.competenciaId ? element.competenciaId : element.id,
            CargoId: this.id,
            CargoCompetenciaNivelId: element.nivel,
            CargoFuncionesId: element.funcion ? element.funcion : null,
          };
        });
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "Continuar",
        });
        return;
      }

      //Guardado
      try {
        console.log("competencias", competencias);
        const r1 = await this.$store.getters.fetchPut({ path: "Cargo/ActualizarCargo/" + this.id, data: this.cargoBase });
        const r2 = await this.$store.getters.fetchPut({ path: "CargoCompetencia/UpdateRelacionCargoCompetenciaByCompetenciaId/" + this.id, data: competencias });
        if (r1.ok && r2.ok) {
          Swal.fire({
            title: "Exito!",
            text: "Se ha guardado correctamente.",
            icon: "success",
            confirmButtonText: "Continuar editando",
            showCancelButton: true,
            cancelButtonText: "Lista de cargos",
            cancelButtonColor: "#dc3545",
            confirmButtonColor: "#28a745",
          }).then((result) => {
            if (!result.isConfirmed) {
              this.$router.go(-1);
            }
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "No se ha podido actualizar el cargo.",
            icon: "error",
            confirmButtonText: "Continuar",
          });
        }
      } catch {
        Swal.fire({
          title: "Error!",
          text: "No se ha podido actualizar el cargo.",
          icon: "error",
          confirmButtonText: "Continuar",
        });
      }
    },

    saveCompetencias() {
      console.log(this.id);
      let competencias;

      try {
        competencias = this.selectedCompetencias.map((element) => {
          //Levantar un error si element de tipo funcion responsabilidad no contiene algún valor
          if (element.tipoCompetencia.descripcion == "Responsabilidad" && !("funcion" in element)) throw new Error("Agregar función a \n" + element.titulo);

          //Levanta un error si no se ha asignado un nivel de competencia
          if (!("nivel" in element)) throw new Error("Agregar nivel a \n" + element.titulo);

          return {
            CompetenciaId: element.id,
            CargoId: this.id,
            CargoCompetenciaNivelId: element.nivel,
            CargoFuncionesId: element.funcion ? element.funcion : null,
          };
        });
      } catch (error) {
        console.log(error);

        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "Continuar",
        });
        return;
      }

      this.$store.commit("setLoading", true);

      this.$store.getters
        .fetchPost({ path: "CargoCompetencia/PostRelacionCargoCompetenciaVarios", data: competencias })
        .then((response) => {
          if (!response.ok) {
            return response.text();
          }
          return response.json();
        })
        .then((result) => {
          if (typeof result == "object") {
            //Crea dos botones para actualizar la pagina y para regresar a la pagina anterior
            Swal.fire({
              title: "Exito!",
              text: "Se ha guardado correctamente.",
              icon: "success",
              confirmButtonText: "Nuevo cargo",
              showCancelButton: true,
              cancelButtonText: "Lista de cargos",
              cancelButtonColor: "#dc3545",
              confirmButtonColor: "#28a745",
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.go();
              } else {
                this.$router.go(-1);
              }
            });
          } else {
            console.log("Error:", result);
            Swal.fire({
              title: "Error!",
              text: "No se ha podido crear el cargo.",
              icon: "error",
              confirmButtonText: "Continuar",
            });
          }
        })
        .catch((error) => {
          console.log("Error:", error);
        })
        .finally(() => {
          this.$store.commit("setLoading", false);
        });
    },
  },
};
</script>

<style scoped>
.btn {
  padding: 2px 5px;
  margin: 0px 2px;
  border: none;
  border-radius: 5px;
}

.btn-pm {
  position: relative;
  min-width: 20px;
  height: 20px;
  text-align: center;
}

.btn-pm i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sm-input {
  height: auto;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 3px;
  line-height: 1.5;
}

.limit-scroll-height {
  position: relative;
  height: 80vh;
  scroll-behavior: smooth;
  overflow-y: auto;
  border-radius: 3px;
  border: 0.5px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
}

.limit-scroll-height::-webkit-scrollbar {
  width: 0;
}

.limit-scroll-height > * {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
}

.limit-scroll-height > *:first-child {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: rgb(28, 47, 78);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
